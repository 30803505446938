import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql, useStaticQuery} from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteTitle: title
        siteDesc: description
      }
    }
  }
`


const SEO = ({title , description}) => {
    const {site} = useStaticQuery(query);
    const {siteTitle, siteDesc} = site.siteMetadata;
    return <Helmet htmlAttributes={{lang:'es'}} title = {`${title} | ${siteTitle}`}>
        <meta name = "description" content = {description || siteDesc}></meta>
    </Helmet>

}

export default SEO
